<template>
  <!-- <modal :title="ticket.name + 'Order Notes'" v-if="active" :active="true"> -->
  <TTDialog title="Email List" :active="active" v-on:close="close">
    <v-simple-table>
      <template v-slot:default>
        <!-- <thead>
          <tr>
            <th class="text-left">
              Email
            </th>
         
          </tr>
        </thead> -->
        <tbody>
          <tr v-for="(email, index) in emails" :key="index">
            <td>{{ email }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- <div class="row" v-for="(email, index) in emails" :key="index">
      <div class="col full">
        <FormulateInput class="form-item" readonly type="text" :value="email" />
      </div>
    </div> -->
    <!-- <div v-for="(email, index) in emails" :key="index">
        <FormulateInput class="form-item" readonly type="text" :value="email" />
      </div>
    </v-row> -->

    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <FormulateInput type="button" name="Add Note" @click="saveNote" />
    </v-card-actions> -->
  </TTDialog>
</template>

<script>
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import Modal from "@/components/Modal.vue";
import TTDialog from "@/components/TTDialog.vue";

library.add(faTimes);

export default {
  components: {
    Modal,
    DiyoboButton,
    FontAwesomeIcon,
    TTDialog
  },
  props: {
    emails: Array
  },
  data() {
    return {
      // active: false,
      note: "",
      notes: [{ value: "Notes", date: "12 April" }],
      ticket: {},
      active: false
    };
  },
  methods: {
    open() {
      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
    },
    saveNote() {
      this.$emit("save", this.ticket, this.note);
      this.notes.push({
        value: this.note,
        date: dateFormat(new Date(), "mmmm dd, yyyy, h:MM TT")
      });
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      let targetElement = document.querySelector("#modal-scroll-container");
      disableBodyScroll(targetElement);
    },
    enter(el) {
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.opacity = 0;
    }
  },
  created() {
    // this.notes = this.ticket.notes;
  }
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  width: 3px;
  color: #000000;
}
::-webkit-scrollbar-track {
  display: none;
}
.modal-mask {
  //position: absolute;
  position: fixed;
  /* margin: auto; */
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 0.4s;

  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #3333339d;

    .modal-container {
      margin: auto;
      padding: 2px;
      position: relative;
      width: 100%;
      //skinny
      //max-width: 530px;
      //fat
      max-width: 700px !important;

      background-color: #4a639e;
      background-image: linear-gradient(160deg, #4eb95e, #4a639e);
      box-shadow: 1px 1px 8px 2px #111111c2;
      border-radius: 8px;
      transition: top 0.5s ease;

      #remove-pop-up {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }

      .modal-header,
      .modal-body {
        min-height: 50px;
      }

      .modal-header {
        display: flex;
        align-items: center;
        //justify-content: space-between;
        height: 80px;
        text-align: center;
        padding: 2px 20px;
        border-radius: 7px 7px 0 0;
        background-color: var(--navbar-background);

        .modal-header-title {
          width: 100%;
          margin: auto;
          height: fit-content;
        }
        .modal-header-text {
          width: 100%;
          margin: 0;
          margin-bottom: 15px;
          height: fit-content;
        }
      }
      // end modal-header
      .bottom {
        border-radius: 0px 0px 7px 7px;
      }
      .modal-body {
        padding: 0em 20px;
        padding-top: 1em;
        padding-bottom: 1em;
        //border-radius: 0 0 7px 7px;
        background-color: var(--content-background);
        // box-shadow: inset 0 0 8px 3px #11111191;
        max-height: 560px;
        overflow: auto;

        .modal-body-title {
          margin-top: 0px;
        }

        .modal-body-content {
          margin: 7.5px 0px;
        }

        h3,
        h4,
        h2 {
          margin: 15px 0px;
          text-align: center;
        }

        .modal-body-container .error-label {
          margin-bottom: 0.75em;
          color: #e60022;
          text-align: center;
          font-weight: 600;
        }

        .basic-info {
          display: flex;
          justify-content: space-evenly;
          p {
            font-size: 1em;
          }
        }

        .basic-description {
          p {
            text-align: center;
          }
        }

        .venue-info {
          text-align: center;
          .venue-date-text {
            width: 45%;
            display: inline-block;
          }
          .venue-date-info {
            display: flex;
            justify-content: space-evenly;
          }
        }

        .table {
          width: 100%;
          text-align: center;
          margin: 18px 0px;
        }
        // end button-wrapper
      }
      // end modal-body
      .modal-btn-ctn {
        display: flex;
        justify-content: space-evenly;
        padding: 15px 0px;
        width: 100%;
      }
      p {
        font-size: 1em !important;
      }
    }
    // end modal-dialog
  }
  // end modal-inner
}
</style>
