<template>
  <div class="csv-importer" :class="{ disabled: disabled }">
    <tier-email-list-modal ref="tierEmailList" :emails="value" />
    <div class="disabled-layer" v-if="disabled">
      <h3 class="message" @click="$refs.tierEmailList.open()">
        {{ disabledMsg }}
      </h3>
    </div>
    <input
      type="file"
      name="csv"
      id="csv"
      accept="text/csv"
      ref="input"
      @input="onUploadCSV"
    />
    <div class="left">
      <h4>Import via CSV</h4>
      <span>
        <span class="title">Column Headers:</span>
        <span v-for="(column, index) in columns" class="column" :key="index">
          {{ column.name }}
        </span>
      </span>
      <span v-if="error" class="error">
        {{ error }}
      </span>

      <p>
        <b>Note:</b>
        Rows that are missing necessary information will be ignored.
      </p>
    </div>
    <div class="right">
      <div v-if="fileName" class="file-info">
        <span>{{ truncatedFileName }}</span>
        <span>({{ importCount }} items)</span>
      </div>
      <diyobo-button type="primary" txt="Import" @click="onClickImport" />
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import Papa from "papaparse";
import TierEmailListModal from "../modals/TierEmailListModal.vue";

export default {
  name: "CSVImporter",
  components: {
    DiyoboButton,
    TierEmailListModal
  },
  props: {
    disabled: Boolean,
    disabledMsg: {
      type: String,
      default: "Importing is disabled. Open the Email List."
    },
    value: Array,
    type: String,
    issuedTo: String,
    columns: {
      type: Array
    }
  },
  data() {
    return {
      importCount: 0,
      fileName: "",
      error: null,
      contacts: null,
      maxFileNameLength: 25
      // columns: [
      //   { name: "first_name", required: true },
      //   { name: "last_name", required: true },
      //   { name: "email", required: true },
      //   { name: "phone" },
      //   { name: "tier", required: true },
      //   { name: "quantity", required: true }
      // ]
    };
  },
  computed: {
    properties() {
      return this.columns.map(a => {
        return { [a.property]: a.name };
      });
    },
    truncatedFileName() {
      if (this.fileName.length <= this.maxFileNameLength) {
        return this.fileName;
      } else {
        // Get the length of the extension
        const extensionLength = this.fileName.split(".").pop().length;

        // Calculate the length of the filename excluding the extension
        const filenameLength = this.maxFileNameLength - 3 - extensionLength; // Subtract 3 for the ellipsis and the dot separator

        // Extract the filename without the extension
        const truncatedFilename = this.fileName.substring(0, filenameLength);

        // Concatenate the truncated filename with ellipsis and the extension
        return truncatedFilename + "..." + this.fileName.slice(-7);
      }
    }
  },
  methods: {
    resetData() {
      this.importCount = 0;
        this.fileName = "";
        this.error = null;
        this.contacts = null;
    },
    onClickImport() {
      this.$refs.input.click();
    },
    onUploadCSV(event) {
      let csv = event.target.files[0];

      Papa.parse(csv, {
        header: true,
        complete: result => {
          const contacts = [];
          const missingColumns = this.columns.filter(
            c => c.required && !result.meta.fields.includes(c.name)
          );
          const requiredColumns = this.columns.filter(c => c.required);

          if (missingColumns.length > 0) {
            this.error =
              "Missing required columns: " +
              missingColumns.map(c => c.name).join(", ");
            return;
          }

          if (contacts.length > 50000) {
            this.error =
              "The maximum amount of contacts that can be imported is 50000";
            return;
          }

          this.error = null;

          let validData = result.data.filter(row => {
            return requiredColumns.every(header => {
              return row.hasOwnProperty(header.name) && row[header.name];
            });
          });

          for (const item of validData) {
            // if (item.email == undefined) {
            //   continue;
            // }
            // this.columns.forEach(col => {
            //   if(col.name && item.tier === undefined) {
            //     continue
            //   }
            // });

            if (
              this.type === "ComplimentaryTickets" &&
              this.issuedTo === "individual"
            ) {
              contacts.push({
                firstName: item.first_name,
                lastName: item.last_name,
                email: item.email,
                phone: item.phone,
                tier: item.tier,
                quantity: item.quantity || 1,
                language: item.language || "",
                location: item.location || "",
                reason: item.reason || "",
                delete: true
              });
            } else if (
              this.type === "ComplimentaryTickets" &&
              this.issuedTo === "group"
            ) {
              contacts.push({
                firstName: item.groupName,
                lastName: "",
                email: item.email,
                phone: item.phone,
                tier: item.tier,
                quantity: item.quantity || 1,
                language: item.language || "",
                location: item.location || "",
                reason: item.reason || "",
                delete: true
              });
            } else if (
              this.type === "WillCall" &&
              this.issuedTo === "individual"
            ) {
              contacts.push({
                firstName: item.first_name,
                lastName: item.last_name,
                email: item.email,
                phone: item.phone,
                tier: item.tier,
                quantity: item.quantity || 1,
                pickupLocation: item.pickup_location,
                language: item.language || "",
                location: item.location || "",
                reason: item.reason || "",
                delete: true
              });
            } else if (this.type === "WillCall" && this.issuedTo === "group") {
              contacts.push({
                firstName: item.groupName,
                lastName: "",
                email: item.email,
                phone: item.phone,
                tier: item.tier,
                quantity: item.quantity || 1,
                pickupLocation: item.pickup_location,
                language: item.language || "",
                location: item.location || "",
                reason: item.reason || "",
                delete: true
              });
            } else if (this.type === "contact-list") {
              contacts.push({
                first_name: item.first_name,
                last_name: item.last_name,
                email: item.email,
                phone: item.phone || '',
              });
            } else {
              let rowData = {};
              requiredColumns.forEach(header => {
                rowData[header.name] = item[header.name];
              });
              contacts.push(rowData);
              // contacts.push(item);
            }
          }

          this.importCount = contacts.length;

          this.fileName = csv.name;

          this.contacts = contacts;
          this.$emit("input", [...contacts]);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.csv-importer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--dashboard-border);
  border-radius: 8px;
  box-shadow: var(--table-shadow);
  position: relative;
  .disabled-layer {
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1f2025d4;
    z-index: 2;
    right: 0;
    top: 0;
    border-radius: inherit;
    cursor: not-allowed;

    .message {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  #csv {
    display: none;
  }

  .left {
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
    }

    h4 {
      margin: 0 0 8px 0;
    }

    .title {
      margin-bottom: 5px;
      display: inline-flex;
    }

    .column {
      padding: 2px 4px;
      margin: 4px;
      background: var(--event-border);
      border-radius: 4px;
      display: inline-flex;
    }

    .error {
      color: var(--error-red);
    }
  }

  .right {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    max-width: 36%;

    .file-info {
      span {
        margin-right: 4px;
        word-break: break-all;
      }
    }
  }
}
</style>
